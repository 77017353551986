import React, { useEffect, useRef } from 'react';
import './Home.css';
import { Card, Button } from "antd";
import { PhaserGame } from "../games/tutorial/PhaserGame";
import { gameEvents } from '../games/tutorial/gameEvents';

function Gaming() {
    const phaserRef = useRef();

    const handleReplay = () => {
        phaserRef.current.scene.scenes[0].scene.restart();
    };

    const handleGameOver = (data) => {
        console.log('Game Over event received:', data);
    };

    useEffect(() => {
        gameEvents.on('game-over', handleGameOver);

        return () => {
            gameEvents.off('game-over', handleGameOver); // Cleanup
        };
    }, []);

    return (
        <div className="Gaming">
            <Card>
                <PhaserGame ref={phaserRef}/>
                <Button style={{margin: 10}} onClick={handleReplay}>Replay</Button>
            </Card>
        </div>
    );
}

export default Gaming;