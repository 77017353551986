import React from "react";
import './Home.css';
import { Card } from "antd";
import FreeDrawing from "../components/FreeDrawing";

function Home() {
  
  return (
    <div className="Home">
      <Card >
        <FreeDrawing />
      </Card>
      
    </div>
  );
}

export default Home;

/* CanvasDraw: https://www.npmjs.com/package/react-canvas-draw */

