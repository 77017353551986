import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import './App.css';
import ProtectedRoute from "./components/ProtectedRoute";
import Home from './pages/Home';
import Authentication from "./components/Authentication";
import Gaming from "./pages/Gaming";
import Settings from "./components/Settings";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  if (isAuthenticated === null) {
    // Render a loading state while waiting for authentication status
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/auth" element={<Authentication />} />
        <Route exact path="/" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Home /></ProtectedRoute>} />
        <Route exact path="/game" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Gaming /></ProtectedRoute>} />
        <Route exact path="/settings" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Settings /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
