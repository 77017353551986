import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { Card, Button, Form, Input, Alert } from "antd";

const Authentication = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('login');

    const navigate = useNavigate();
    const auth = getAuth();

    const handleError = (e) => {
        try {
            setError("ERROR: " + e.substring(22, e.length -2))
        }
        catch {
            setError(e);
        }
    }

    const handleRegister = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                handleError(error.message)
            });
    }

    const handleLogin = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                navigate('/');
            })
            .catch((error) => {
                handleError(error.message)
            });
    };

    const onTabChange = () => {
        if(activeTabKey === 'login') {
            setActiveTabKey('register');
        }
        else {
            setActiveTabKey('login');
        }
    }

    const tabList = [{ key: 'login', label: 'Login' }, { key: 'register', label: 'Register' }]
    const contentList = {
        login: <Form labelCol={{span: 8}} wrapperCol={{span: 16}} style={{maxWidth: 600}} name="login" onFinish={handleLogin} autoComplete="on">
            <Form.Item label="Email" name="email" rules={[{required: true, message: 'Please input your email!'}]}>
                <Input name="email" type="email" autoComplete='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            </Form.Item>

            <Form.Item label="Password" name="password" rules={[{required: true, message: 'Please input your password!',}]}>
                <Input.Password name="password" type="password" autoComplete='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"/>
            </Form.Item>

            <Form.Item label={null}>
                <Button type="primary" htmlType="submit">
                    Login
                </Button>
            </Form.Item>
        </Form>,

        register: <Form labelCol={{span: 8}} wrapperCol={{span: 16}} style={{maxWidth: 600}} name="register" onFinish={handleRegister} autoComplete="off" >
            <Form.Item label="Email" name="email" rules={[{required: true, message: 'Please input your email!'}]}>
                <Input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            </Form.Item>

            <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!', }]}>
                <Input.Password name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            </Form.Item>

            <Form.Item label={null}>
                <Button type="primary" htmlType="submit">
                    Register
                </Button>
            </Form.Item>
        </Form >
    }

    return (
        <div>
            <Card activeTabKey={activeTabKey} onTabChange={onTabChange} tabList={tabList}>
                {contentList[activeTabKey]}
                {error && <Alert message={error} type="error" />}
            </Card>
        </div>
    );
};

export default Authentication;
