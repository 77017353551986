import React, { useState, useRef, useEffect } from "react";
import CanvasDraw from "react-canvas-draw";
import { ref, set, get } from "firebase/database";
import { db } from "../firebase-config";
import { Button } from 'antd';

function FreeDrawing() {
  const canvasRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState(getCanvasSize());
  const emptyCanvas = {"lines":[],"width":canvasSize.width,"height":canvasSize.height};
  const [canvasData, setCanvasData] = useState(emptyCanvas);

  function getCanvasSize() {
    if (window.innerWidth < window.innerHeight) {
      return { width: window.innerWidth * 0.8, height: window.innerWidth * 0.8 };
    } else {
      return { width: window.innerHeight * 0.8, height: window.innerHeight * 0.8 };
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setCanvasSize(getCanvasSize());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function writeData(data) {
    set(ref(db, 'test'), {
      content: data
    });
  }

  const testRef = ref(db, 'test/content');

  function loadData() {
    get(testRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.val().lines !== undefined) {
        setCanvasData(snapshot.val());
      } else {
        setCanvasData(emptyCanvas)
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  useEffect(() => {
    if (canvasRef.current.saveData !== canvasData) {
      canvasRef.current.saveData = canvasData;
    }
  }, [canvasData])

  return (
    <div>
      {canvasSize.width && canvasSize.height && (
        <CanvasDraw
          ref={canvasRef}
          canvasWidth={canvasSize.width}
          canvasHeight={canvasSize.height}
          saveData={JSON.stringify(canvasData)}
          hideGrid={true}
          backgroundColor="#F1E3D3"
          brushColor="#99C1B9"
        />
      )}
      <Button style={{ margin: 10 }} onClick={() => { canvasRef.current.clear(); setCanvasData(emptyCanvas); }}>Reset</Button>
      <Button style={{ margin: 10 }} onClick={() => { const data = JSON.parse(canvasRef.current.getSaveData()); setCanvasData(data); writeData(data) }}>"Save"</Button>
      <Button style={{ margin: 10 }} onClick={() => { loadData(); }}>"Load"</Button>
    </div>
  );
}

export default FreeDrawing;
