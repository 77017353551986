import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { FloatButton } from "antd";
import { UpOutlined, EditOutlined, PlayCircleOutlined, UserOutlined } from '@ant-design/icons';

const ProtectedRoute = ({ isAuthenticated, children }) => {
    const navigate = useNavigate();

    if (!isAuthenticated) {
        return <Navigate to="/auth" />;
    }
    return <div>
        {children}
        <FloatButton.Group trigger="hover" type="primary" icon={<UpOutlined />}>
        <FloatButton icon={<EditOutlined />} onClick={() => navigate("/")}/>
        <FloatButton icon={<PlayCircleOutlined onClick={() => navigate("/game")}/>} />
        <FloatButton icon={<UserOutlined />} onClick={() => navigate("/settings")}/>
      </FloatButton.Group>
    </div>;
};

export default ProtectedRoute;
