import React, { useRef, useLayoutEffect, forwardRef } from 'react';
import StartGame from './main';
import './game.css'

export const PhaserGame = forwardRef(function PhaserGame(_ ,ref) {
    const gameContainer = useRef();

    useLayoutEffect(() => {
        if (gameContainer.current === undefined) {
            gameContainer.current = StartGame("game-container")

            if(ref != null) {
                ref.current = {game:gameContainer.current, scene: gameContainer.current.scene}
            }
        }

        return () => {
            gameContainer.current.destroy(true);
            gameContainer.current = undefined;
        }

    }, [ref]);

    return <div id='game-container' />;
});
