import React, { useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { Card, Button, Alert } from "antd";

const Settings = () => {
    const [error, setError] = useState('');
    const [user, setUser] = useState('');
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(user.email);
        }
    });

    const handleSignOut = () => {
        signOut(auth).then(() => {
            setUser('');
        }).catch((error) => {
            setError(error.message);
        })
    }

    return (
        <Card>
            <p>Logged in as: {user}</p>
            <Button onClick={handleSignOut}>Sign Out</Button>
            {error && <Alert message={error} type="error" />}
        </Card>

    );
};

export default Settings;
